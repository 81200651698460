import { Container, Stack, Typography } from "@mui/material";
import CustomText from "../lib/CustomText";

export default function UniqueShareduled() {
  return (
    <Container
      maxWidth="lg"
      id="unique-shareduled-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "flex-start",
        alignItems: "center",
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Stack
        spacing={{ xs: 2, sm: 3 }}
        useFlexGap
        sx={{
          height: "inherit",
          width: { xs: "100%", sm: "100%" },
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            flexWrap: "wrap",
            textAlign: "center",
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
          }}
        >
          <Typography
            variant="h4"
            component="span"
            sx={{ fontStyle: "italic" }}
          >
            Shareduled
          </Typography>{" "}
          Is Unlike Any App You've Ever Used Before… (And Way Better Too)
        </Typography>

        <CustomText textPlain={`Hey, Joseph here…`} />
        <CustomText
          textPlain={`If you're reading this, it means you need a better appointment booking system.`}
        />
        <CustomText
          textPlain={`Well, creating software that makes life easy for businesses like yours is what I do.`}
        />
        <CustomText
          textPlain={`I know a lot of businesses who lose a lot of money preparing for clients that never show up…`}
        />
        <CustomText
          textPlain={`I myself have been frustrated by spending hours upon hours online… looking for the perfect massage therapist who is FREE.`}
        />
        <CustomText
          textPlain={`I understand both sides of the spectrum: the business owner and the client in dire need for a treat…`}
        />
        <CustomText textPlain={`I've been there. `} />

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          This made me gather my 5+ years of experience creating powerful
          software for national brands to create{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          >
            Shareduled
          </Typography>{" "}
          for you.
        </Typography>

        <CustomText textPlain={`As you can tell by now…`} />
        <CustomText textPlain={`It's unlike any other app out there.`} />
        <CustomText
          textPlain={`It was designed not just for any service-based business…`}
        />

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          But{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "300",
              alignSelf: "flex-start",
              textDecoration: "underline",
            }}
          >
            specifically
          </Typography>{" "}
          for the owners of barbershops, massage houses, spa and salons.
        </Typography>

        <CustomText textPlain={`And this is because…`} />
      </Stack>
    </Container>
  );
}
