import { Button, Container, Stack, Typography } from "@mui/material";
import CustomText from "../lib/CustomText";
import { scrollToSection } from "../../helpers/miscellaneous";
import { AppColours } from "../../helpers/colors";

export default function ProblemSolutionLead() {
  return (
    <Container
      maxWidth="lg"
      id="problem-solution-lead-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "flex-start",
        alignItems: "center",
        pb: { xs: 6, sm: 7 },
      }}
    >
      <Stack
        spacing={{ xs: 2, sm: 3 }}
        useFlexGap
        sx={{
          height: "inherit",
          width: { xs: "100%", sm: "100%" },
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
            mt: { xs: 4, sm: 6 },
          }}
        >
          Let's be very clear,{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          >
            Shareduled
          </Typography>{" "}
          is{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "300",
              alignSelf: "flex-start",
              textDecoration: "underline",
              fontStyle: "italic",
            }}
          >
            not
          </Typography>{" "}
          your typical appointment booking app.
        </Typography>

        <CustomText
          textPlain={`Whether you own a busy barbershop or a salon that's constantly flooded
          with old and new clients…`}
        />

        <CustomText
          textPlain={`Or you own a spa or a massage center that's completely booked, week
          after week.`}
        />

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          You no longer need to agonize over manually sorting out appointments
          with clients{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "300",
              alignSelf: "flex-start",
              textDecoration: "underline",
              fontStyle: "italic",
            }}
          >
            ever
          </Typography>{" "}
          again.
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          With{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          >
            Shareduled,
          </Typography>{" "}
          everything happens{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "300",
              alignSelf: "flex-start",
              textDecoration: "underline",
              fontStyle: "italic",
            }}
          >
            live
          </Typography>{" "}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          Your clients enjoy{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "300",
              alignSelf: "flex-start",
              textDecoration: "underline",
            }}
          >
            flexible
          </Typography>{" "}
          bookings…
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          You share{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "300",
              alignSelf: "flex-start",
              textDecoration: "underline",
            }}
          >
            live availability changes
          </Typography>{" "}
          with them, immediately get updates about their bookings…
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          …and effortlessly manage payment{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "300",
              alignSelf: "flex-start",
              textDecoration: "underline",
            }}
          >
            in real time
          </Typography>
          .
        </Typography>

        <CustomText textPlain={`Without wasting time answering phone calls…`} />

        <CustomText
          textPlain={`Or having a constant back-and-forth with countless clients on IG.`}
        />

        <CustomText
          textPlain={`There’s no complicated or complex steps to take.`}
        />

        <CustomText
          textPlain={`No alphanumeric, symbolic passwords to “commit to memory”.`}
        />

        <CustomText
          textPlain={`No special technical skill needed and it's all in the palm of your
          hand.`}
        />

        <CustomText
          textPlain={`You just plug this into your business, set your availability period
          and you're good to go.`}
        />

        <img src="/A-spa-manager.png" className=" rounded-md w-full md:w-full md:h-[600px]" alt="" />

        <Button
        color="primary"
        variant="contained"
        onClick={() => {
          scrollToSection("shareduled-power-section");
        }}
        sx={{
          alignSelf: "center",
          borderRadius: "8px",
          textTransform: "none",
          backgroundColor: AppColours.MainRed,
          padding: "12px",
          width: { xs: "100%", sm: "80%", md: "60%" },
        }}
      >
        Take Back Your Time: Start Today
      </Button>

        <Button
          color="primary"
          variant="text"
          fullWidth
          onClick={() => {
            scrollToSection("pricing-and-start-section");
          }}
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            textDecoration: "underline",
          }}
        >
          Skip and Get Life Time Access Now
        </Button>

      </Stack>
    </Container>
  );
}
