import React from "react";
import { Button } from "@mui/material";
import { scrollToSection } from "../../helpers/miscellaneous";
import { AppColours } from "../../helpers/colors";

const LeadMagnet = () => {
    return (
        <section className="font-light mx-16">
            <h1 className="text-4xl font-bold md:mx-14 text-center text-[#550066] mb-6">See For Yourself: The Real Power of Shareduled... So You Can Project Your Profits <span className="underline">With Precision</span></h1>

            <h2 className="text-2xl font-semibold text-[#550066] mb-6">Want to see real numbers on the impact Shareduled can make on your revenue and efficiency?</h2>

            <div className="flex flex-col gap-5">
                <p>Imagine a future where every minute translates to money well earned</p>
                <p>Every business move should be a step towards greater profits and our ROI calculator paints a clear picture of this future, showing you the direct impact of Shareduled on your finances.</p>
                <p>Take control of your earnings today, dive into the numbers and start making every second count. </p>
                <p>The future is bright, and it's just one click away.</p>
            </div>

            <h3 className="text-2xl font-bold text-[#550066] mt-6">You will see how you can:</h3>
            <ul className="list-disc flex flex-col gap-5 ml-9 mb-9">
                <li>Make informed decisions about your revenue and profitability</li>
                <li>Unlock hidden profit potentials within your business.</li>
                <li>Assess suitability of Shareduled based on data.</li>
                <li>Make a confident investment decision for your business.</li>
                <li>Gain a clear understanding of Shareduled's impact</li>
            
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                    scrollToSection("shareduled-power-section");
                    }}
                    sx={{
                    alignSelf: "center",
                    borderRadius: "8px",
                    textTransform: "none",
                    backgroundColor: AppColours.MainRed,
                    padding: "12px",
                    width: { xs: "100%", sm: "80%", md: "60%" },
                    margin: "auto",
                    }}
                >
                    Take Back Your Time: Start Today
                </Button>
            </ul>


            <Button
                color="primary"
                variant="text"
                fullWidth
                onClick={() => {
                    scrollToSection("pricing-and-start-section");
                }}
                sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    textDecoration: "underline",
                }}
            >
                Skip and Get Life Time Access Now
            </Button>
        </section>
    )
}

export default LeadMagnet