import { Box, Container, Link, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { AppColours } from "../../helpers/colors";

type IFAQ = {
  question: string;
  answer: string;
  answer2?: string;
};

const data: IFAQ[] = [
  {
    question: "Is my data and that of my clients secure with Shareduled?",
    answer:
      "Hey there! We know your client data is super important, so keeping it safe is our top priority. That's why Shareduled uses top-notch encryption and secure cloud storage to keep everything locked down tight. We also follow strict industry rules for extra peace of mind. Think of it like Fort Knox for your appointments – totally secure!",
  },
  {
    question: "What if I only have a few appointments per week?",
    answer:
      "Whether you're just starting out or have a busy schedule, Shareduled can help! Don't let a few appointments hold you back. Shareduled automates things like confirmations and cancellations, saving you tons of time and effort, even if you don't have a ton of appointments on the books.",
  },
  {
    question: "Can I customize Shareduled to match my brand?",
    answer:
      "Of course! You can totally personalize Shareduled to match your brand.  This way, your clients will have a smooth experience that reflects your professionalism – think of it like having your logo and colors everywhere they look!",
  },
  {
    question: "Can Shareduled handle high volumes of bookings",
    answer:
      "Totally! Shareduled grows with you, no matter how busy you get.  It can handle tons of bookings without slowing down or getting clunky. Plus, you can even manage group bookings for workshops or meetings - like scheduling a whole fitness class in one go!",
  },
  {
    question: "I can't find pricing details or plans. Why is that?",
    answer:
      "We skipped the pricing on this page for a reason! We want you to try out the ROI calculator and see if Shareduled is a good fit for you before you commit. Think of it as a test drive. Here's the deal: Building a custom app like this would cost you a ton –  we're talking at least $35,000! Plus, similar scheduling software can run up to $762 per year with hidden fees on top.  Crazy, right?",
    answer2:
      "That's why we have a special limited-time offer just for early adopters like you.  It's nowhere near $700, and guess what? If you try it for a year and decide it's not for you, we'll give you a full refund and even throw in an extra month for free! We just ask that you're honest with us about your experience.",
  },
  {
    question: "Does Shareduled integrate with my existing calendar?",
    answer:
      "Juggling multiple calendars is a pain, right? Shareduled plays nicely with popular platforms like Google and Outlook, so you can see all your appointments in one place. No more double-booking or scheduling nightmares - just smooth sailing from here on out!",
  },
  {
    question: "Does Shareduled offer customer support?",
    answer:
      "We've got your back!  Our awesome customer support team is here to answer any questions you have about Shareduled.  Need a quick tip? Hop on live chat. Stuck on something tricky? Shoot us an email. There's also a ton of helpful info we are working on - our knowledge base and community forums. We want you to succeed with Shareduled, so don't hesitate to reach out!",
  },
  {
    question: "Can I access Shareduled from my mobile device?",
    answer:
      "Yes way! Shareduled has a mobile app for both iPhone and Android (Playstore and Appstore) so you can manage your appointments and business from anywhere.  Need to reschedule on the fly? No problem! Want to check your schedule while you're out and about? Easy peasy!  Stay on top of things wherever you go.",
  },
  {
    question: "What kind of businesses is Shareduled best for?",
    answer:
      "Shareduled is super versatile and can work for pretty much any appointment-based business, but we were thinking of you wellness rockstars in particular!  Whether you're a barber keeping your clients looking sharp, a massage therapist helping folks unwind, or a salon or spa working your magic, Shareduled can streamline your scheduling and make your life easier.",
  },
  {
    question: "How often do Shareduled release updates?",
    answer:
      "We're constantly making Shareduled even better!  We listen to what awesome users like you need and what's going on in the industry, then release regular updates with new features, improvements, and a smoother experience overall.  Think of it as getting better and better with every season, just like your favorite show!",
  },
];

const FAQInternal = (
  props: IFAQ & {
    index: number;
    expanded: boolean;
    handleChange: (
      panel: string
    ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  }
) => (
  <Accordion
    expanded={props?.expanded}
    onChange={props?.handleChange(`panel${props?.index}`)}
    sx={{
      mb: { xs: 1, sm: 2, md: 2 },
      border: "none",
      borderRadius: 2,
      backgroundColor: AppColours.LightPurple,
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel${props?.index}-content`}
      id={`panel${props?.index}-header`}
    >
      <Typography variant="body1" sx={{ fontWeight: "500" }}>
        {props?.question}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography
        variant="body2"
        gutterBottom
        sx={{
          pl: { xs: 1, sm: 2, md: 3 },
          mb: { xs: 2, sm: 2, md: 3 },
          fontWeight: "300",
        }}
      >
        {props?.answer}
      </Typography>
      {props?.answer2 && (
        <Typography
          variant="body2"
          sx={{ pl: { xs: 1, sm: 2, md: 3 }, fontWeight: "300" }}
        >
          {props?.answer2}
        </Typography>
      )}
    </AccordionDetails>
  </Accordion>
);

export default function FAQ() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Container
      maxWidth="lg"
      id="faq-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 4, sm: 6 },
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: (theme) => {
            return {
              [theme.breakpoints.down("sm")]: {
                display: "flex-start",
              },
            };
          },
          pb: { xs: 6, sm: 8 },
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
            display: "flex",
            alignSelf: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  display: "flex-start",
                },
              };
            },
            flexWrap: "wrap",
            textAlign: "left",
          }}
        >
          Got Questions? Let's Clear It Up
        </Typography>
      </Box>
      <Box
        sx={{
          width: "inherit",
          alignSelf: "center",
        }}
      >
        {data.map((x, index) => (
          <FAQInternal
            key={index.toString()}
            {...x}
            index={index}
            expanded={expanded === `panel${index}`}
            handleChange={handleChange}
          />
        ))}
      </Box>
      <Typography
        variant="body1"
        sx={{ fontWeight: "300", pt: { xs: 2, sm: 3, md: 4 } }}
      >
        Still have more questions? Send an email to&nbsp;
        <Link
          color="inherit"
          href="mailto:mia.chloe@shareduled.com?subject=Question About Shareduled&body=Hello Team Shareduled,"
          target="_blank"
          rel="noopener noreferrer"
        >
          mia.chloe@shareduled.com
        </Link>
      </Typography>
    </Container>
  );
}
