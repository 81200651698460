import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useRef, useState } from "react";
import { scrollToSection } from "../../helpers/miscellaneous";
import CustomText from "../lib/CustomText";

type ISEData = {
  header: string;
  src: "image" | "video";
  subHeader: string;
  textBlack1: string;
  textRed1: string;
  textBlack2: string;
  textList?: string[];
};

const data: ISEData[] = [
  {
    header: "",
    src: "image",
    subHeader: "According to Zippia Research",
    textBlack1:
      "Shareduled can help businesses save up to 10 hours per week through automation and streamline operations, leading to a potential ",
    textRed1: "yearly 20% increase in revenue",
    textBlack2: ".",
  },
];
const dataSecond: ISEData[] = [
  {
    header: "",
    src: "image",
    subHeader: "Your data is more secure than your safe at home",
    textBlack1: "We understand the importance of protecting your client data. ",
    textRed1:
      "Shareduled prioritizes security and privacy with industry-leading practices",
    textBlack2: ":",
    textList: [
      "256-bit encryption safeguards your data both in transit and at rest.",
      "Secure cloud storage with trusted providers ensures data accessibility and protection.",
      "Regular backups guarantee information recovery in case of unforeseen circumstances.",
    ],
  },
];

const ServiceExcellenceInternal = (
  props: ISEData & {
    image: any;
  }
) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver(() => {
      const { offsetWidth } = ref.current as any;
      setWidth(offsetWidth);
    });

    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Grid
      ref={ref}
      item
      xs={12}
      sm={12}
      sx={{
        height: "inherit",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        elevation={2}
        raised
        sx={{
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          pr: { xs: 3, sm: 4, md: 4 },
          pl: { xs: 3, sm: 4, md: 4 },
          pt: { xs: 3, sm: 4, md: 5 },
          pb: { xs: 3, sm: 4, md: 5 },
          boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            lineHeight: "26px",
            textAlign: "center",
            fontWeight: "600",
            color: AppColours.MainGreen,
            pt: { xs: 3, sm: 4, md: 5 },
            pb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {props?.header}
        </Typography>

        <CardMedia
          component={props?.src === "video" ? "video" : "img"}
          loading="lazy"
          src={props?.src}
          image={props?.image}
          autoPlay={props?.src === "video" ? true : undefined}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: { xs: 150, sm: 240, md: 240 },
            width: 0.8 * width,
          }}
          controls={props?.src === "video" ? true : undefined}
          controlsList={props?.src === "video" ? "nodownload" : undefined}
        />

        <Typography
          variant="body2"
          sx={{
            lineHeight: "26px",
            textAlign: "center",
            fontWeight: "600",
            color: AppColours.MainBlack,
            pt: { xs: 3, sm: 4, md: 5 },
            pb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {props?.subHeader}
        </Typography>

        <Typography
          variant="body1"
          component={"div"}
          sx={{
            lineHeight: "26px",
            textAlign: "left",
            fontWeight: "300",
            color: AppColours.MainBlack,
            pb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {props?.textBlack1}&nbsp;
          <Typography
            variant="body1"
            sx={{
              display: "inline",
              lineHeight: "26px",
              fontWeight: "300",
              color: AppColours.MainRed,
            }}
          >
            {props?.textRed1}
          </Typography>
          {props?.textBlack2}
        </Typography>

        {props?.textList && (
          <List>
            {props.textList.map((x, index) => (
              <ListItem disablePadding key={index.toString()}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2" style={{ fontWeight: "200" }}>
                    {x}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Card>
    </Grid>
  );
};

export default function ServiceExcellence() {
  return (
    <Container
      maxWidth="lg"
      id="service-excellence-section"
      sx={{
        minHeight: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Stack
        spacing={{ xs: 2, sm: 3 }}
        useFlexGap
        sx={{
          height: "inherit",
          width: { xs: "100%", sm: "100%" },
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          pb: { xs: 4, sm: 6 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            flexWrap: "wrap",
            textAlign: "center",
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
          }}
        >
          Average Business Owners Of Barbershops, Massage Houses, Spa And Salons{" "}
          <Typography
            variant="h4"
            component="span"
            sx={{
              flexWrap: "wrap",
              textAlign: "center",
              fontWeight: "600",
              color: (theme) => theme?.palette?.primary?.main,
              textDecoration: "underline",
            }}
          >
            Lose Over $10,946 Worth
          </Typography>{" "}
          Of Customers Every Year…
        </Typography>

        <Typography
          variant="h4"
          sx={{
            flexWrap: "wrap",
            textAlign: "center",
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
          }}
        >
          Don't Let It Happen To You.
        </Typography>

        <CustomText textPlain={`Listen, this is not a bluff.`} />
        <CustomText
          textPlain={`Businesses like yours are estimated to lose over 21% of their customers every year.`}
        />
        <CustomText
          textPlain={`This translates to $10,946 assuming you average $52,123/year.`}
        />
        <CustomText textPlain={`Why?`} />
        <CustomText
          textPlain={`They lack a dialed-in system that effortlessly handles appointments bookings.`}
        />
        <CustomText
          textPlain={`The truth is, you don't have to be among those who are losing money every single day.`}
        />

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          With{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          >
            Shareduled
          </Typography>{" "}
          you get to be among the lucky few who are saved.
        </Typography>
      </Stack>

      <Grid
        container
        spacing={{ xs: 5, sm: 6, md: 7 }}
        sx={{
          mb: { xs: 6, sm: 8, md: 10 },
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "inherit",
        }}
      >
        {data.map((x, index) => {
          let imageData = require("../../assets/langing-page/Zippia-Research.png");

          switch (index) {
            case 1:
              imageData = require("../../assets/langing-page/Zippia-Research.png");
              break;

            default:
              break;
          }

          return (
            <ServiceExcellenceInternal
              key={index.toString()}
              {...x}
              image={imageData}
            />
          );
        })}

        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              flexWrap: "wrap",
              textAlign: "center",
              fontWeight: "600",
              color: (theme) => theme?.palette?.primary?.main,
            }}
          >
            Secure Enough For Your Clients… Simple Enough For You.
          </Typography>
        </Grid>

        {dataSecond.map((x, index) => {
          let imageData = require("../../assets/langing-page/An-icon-of-a-secure-lock-with-a-checkmark-representing-data-security-alongside-a-brief-explanation.png");

          switch (index) {
            case 1:
              imageData = require("../../assets/langing-page/An-icon-of-a-secure-lock-with-a-checkmark-representing-data-security-alongside-a-brief-explanation.png");
              break;

            default:
              break;
          }

          return (
            <ServiceExcellenceInternal
              key={index.toString()}
              {...x}
              image={imageData}
            />
          );
        })}
      </Grid>

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          scrollToSection("shareduled-power-section");
        }}
        sx={{
          alignSelf: "center",
          borderRadius: "8px",
          textTransform: "none",
          backgroundColor: AppColours.MainRed,
          padding: "12px",
          width: { xs: "100%", sm: "80%", md: "60%" },
        }}
      >
        Take Back Your Time: Start Today
      </Button>
    </Container>
  );
}
