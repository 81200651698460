import { Button } from "@mui/material";
import { scrollToSection } from "../../helpers/miscellaneous";
import { AppColours } from "../../helpers/colors";

const WhyShareduled = () => {
    return (
        <section className="flex flex-col items-center gap-4 px-6 my-6 font-light">

            <div className="mx-16">

                <h1 className="text-4xl font-bold md:mx-14 text-center text-[#550066] mb-6">
                    Why Should You Choose Shareduled Over Other Appointment Booking Apps?
                </h1>

                <table className="bg-[#f9e7fc] rounded-md py-3 border-separate border-spacing-y-4">
                    <thead>
                        <tr>
                            <th className="px-6 py-3 text-center md:text-left">Other Appointment Booking Apps</th>
                            <th className="px-6 py-3 text-center">Shareduled</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-6 py-4">Complex to set up and hard to navigate.</td>
                            <td className="px-6 py-4">Simple, easy to navigate app.</td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4">Expensive. Not suitable for small businesses.</td>
                            <td className="px-6 py-4">
                                The best price for owners of barbershops, massage houses, spas, and salons like you.
                            </td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4">Limited customization options for branding.</td>
                            <td className="px-6 py-4">You'll make your clients "feel at home" even before coming to you.</td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4">Secured with lengthy, easy-to-forget seed phrases.</td>
                            <td className="px-6 py-4">
                                Secured with 256-bit encryption, secure cloud storage, and regular backups.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="flex flex-col gap-6">
                <h1 className="text-4xl font-bold md:mx-14 text-center text-[#550066]">
                    Now, Will You Allow Your Clients To Go To A Competitor?
                </h1>
                <p>Are you comfortable with losing clients?</p>
                <p>Are you comfortable with watching your sales dry up?</p>
                <p>Are you okay with disappointing clients and harming your reputaion</p>
                <p>Or do you want to take FULL control of your business?</p>
                <p>If you want something different...</p>
                <p>If you want a smooth scheduling experience...</p>
                <p>If you want to retain more clients and skyrocket your business...</p>
                <p>You're going to have to do something different today!</p>
                <p>Make a new choice...</p>
                <p>And Pursue your new outcome by taking this free ROI Test now</p>

                <img src="/A-spa-manager.png" className=" rounded-md w-full md:w-full md:h-[600px]" alt="" />

                <Button
        color="primary"
        variant="contained"
        onClick={() => {
          scrollToSection("shareduled-power-section");
        }}
        sx={{
          alignSelf: "center",
          borderRadius: "8px",
          textTransform: "none",
          backgroundColor: AppColours.MainRed,
          padding: "12px",
          width: { xs: "100%", sm: "80%", md: "60%" },
        }}
      >
        Take Back Your Time: Start Today
                </Button>

                <Button
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={() => {
                        scrollToSection("pricing-and-start-section");
                    }}
                    sx={{
                        borderRadius: "8px",
                        textTransform: "none",
                        textDecoration: "underline",
                    }}
                >
                    Skip and Get Life Time Access Now
                </Button>
            </div>

        </section>
    );
};

export default WhyShareduled;
